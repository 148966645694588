import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'
import { isEqual } from 'lodash'
import memoizeOne from 'memoize-one'

import { getResourceName, getDelegationName, hasMoreDelegations } from 'lib/resources/transformations'
import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { inventoryPaths, inventoryPaths as paths, RESOURCES_EXTRA } from './index'
import { useDelegationsQuery } from '../delegations/index'

const transform = (resources, delegations) => {
  if (!resources) return undefined

  const moreDelegations = hasMoreDelegations(Object.values(resources))
  return Object.keys(resources)?.reduce((acc, resourceId) => {
    const resource = resources[resourceId]
    acc[resourceId] = {
      ...resource,
      title: getResourceName(resource),
      ...(moreDelegations ? { delegationName: getDelegationName(delegations, resource) } : [])
    }

    return acc
  }, {})
}

const memoizedResponse = memoizeOne(transform, isEqual)

const getResources = async (account: string, filters = {}, limit: number, resourceGroup: string) => {
  const payload = omitEmpty({ filters: filters, limit: limit, resourceGroup: resourceGroup })
  const { data } = await apiFetch(inventoryPaths.resources(account), { method: 'POST', body: JSON.stringify(payload) })

  return data?.reduce((acc, item) => {
    acc[item.id] = item
    return acc
  }, {})
}

export const useGetResourcesQuery = (key: string, filters: any, limit: number, resourceGroup: string) => {
  const { account } = useOrganization()

  const { data: delegationsData } = useDelegationsQuery()

  const queryOpts = useQuery(
    [...RESOURCES_EXTRA, account?.id, key, filters],
    () => getResources(account?.id, filters, limit, resourceGroup),
    {
      enabled: !!account?.id,
      refetchOnWindowFocus: false,
      notifyOnChangeProps: 'tracked',
      staleTime: 30 * 60 * 1000
    }
  )

  const data = memoizedResponse(queryOpts?.data, delegationsData)

  return {
    ...queryOpts,
    data
  }
}
