import React from 'react'
import Items from 'containers/inventory/shared/items'
import { useLocation } from 'react-router-dom'
import { useAccountRoutes } from 'containers/routes'

const EventBridgeRules = ({ rules }) => {
  const { search } = useLocation()
  const routes = useAccountRoutes()

  const formatted = rules?.map(rule => {
    return {
      id: rule.id,
      url: { pathname: routes.inventory.resource.url({ resourceId: rule.id }), search },
      title: rule.name
    }
  })

  return (
    <Items items={formatted} />
  )
}

export default EventBridgeRules
