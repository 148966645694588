import React from 'react'
import { useLocation } from 'react-router-dom'
import Items from 'containers/inventory/shared/items'
import { useAccountRoutes } from 'containers/routes'
import { objectify } from 'lib/utils'

const ClusterInstances = ({ instances, loading, attributes }) => {
  const { search } = useLocation()
  const routes = useAccountRoutes()

  const clusterMembers = JSON.parse(objectify(attributes)?.dbClusterMembers)

  const formatted = instances?.map(item => {
    const member = clusterMembers.find(mem => mem.dbInstanceIdentifier === item.name)

    return ({
      id: item.id,
      title: item.title || item.name,
      url: { pathname: routes.inventory.resource.url({ resourceId: item.id }), search },
      description: member?.isClusterWriter ? 'writer' : 'reader'
    })
  })

  return <Items items={formatted} loading={loading} />
}

export default ClusterInstances
