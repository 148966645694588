import { useQuery, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { getResourceName, getDelegationName } from 'lib/resources/transformations'
import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { DELEGATIONS_LIST } from 'hooks/api'
import { inventoryPaths, RESOURCE_ACTIVE } from './index'
import { isEmpty } from 'lodash'

const getResource = async (account, payload) => {
  const { data } = await apiFetch(inventoryPaths.resources(account), { method: 'POST', body: JSON.stringify({ filters: payload }) })

  return data?.[0]
}

export const useResourceQuery = (resourceId) => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  const delegations = queryClient.getQueryData([...DELEGATIONS_LIST, account?.id]) || []

  const transformResource = resource => (
    {
      ...resource,
      title: getResourceName(resource),
      ...(!isEmpty(delegations) ? { delegationName: getDelegationName(delegations, resource) } : [])
    })

  return useQuery(
    [...RESOURCE_ACTIVE, resourceId],
    () => getResource(account.id, { ids: [resourceId] }),
    {
      select: transformResource,
      enabled: !!account?.id && !!resourceId,
      staleTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
