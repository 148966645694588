import { useQuery } from 'react-query'
import queryString from 'query-string'

import { apiFetch, omitEmpty } from 'lib/action-helpers'
import { billingPaths as paths, PRICES } from './index'
import { useOrganization } from 'hooks/context/organization-context'

const getPrices = async (identity, filters) => {
  const query = queryString.stringify(omitEmpty(filters))

  const data = await apiFetch(paths.filter(paths.prices(identity), query))
  return data.data
}

export type Price = {
  id: string
  nickname: null | string
  metadata: {
    tier?: string
    type?: string
    invocations?: string
    lambdaInvocations?: string
  }
  interval: string
}

export const useGetPricesQuery = (filters?: any) => {
  const { organization } = useOrganization()

  return useQuery<Price[]>(
    [...PRICES, organization.id, filters],
    () => getPrices(organization?.id, filters),
    {
      staleTime: Infinity,
      enabled: !!organization?.id,
      retry: false
    }
  )
}
