import React from 'react'
import { isEmpty } from 'lodash'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'
import InventoryResourceContainer from '../../../container'
import InventoryTags from 'containers/inventory/shared/tags'
import RuleConfig from './config'
import Events from 'containers/inventory/shared/events'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { EVENTBRIDGE_RULE_TRIGGERED_RULES } from '../../../metrics-tabs/services/eventbridge-rule'
import Section from 'components/layout/content/section'
import Tabs from 'components/antd/tabs'

import { useResourcesQuery } from 'hooks/api'

import TargetsList from './targets'

const Config = ({ item }) => {
  const attributes = objectify(item.attributes)

  return (<>
    {item?.tags?.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <RuleConfig attributes={attributes} rule={item} inverse />
  </>)
}

const EventBridgeRule = ({ item }) => {
  const attributeTargets = JSON.parse(item.attributes.find(attr => attr.name === 'targets')?.value || '[]')
  const { data: targetResources } = useResourcesQuery('event-bridge-targets', { arns: attributeTargets.map(({ arn }) => arn) })
  const targets = Object.values(targetResources || {})

  return (
    <>
      <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.EventBridgeRule.id} initialChart={EVENTBRIDGE_RULE_TRIGGERED_RULES}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey='targets' items={[
                { key: 'targets', label: 'Targets', children: !isEmpty(targets) && <TargetsList targets={targets} /> },
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default EventBridgeRule
