import React from 'react'
import { Slider, Radio, Button } from 'antd'
import numeral from 'numeral'
import { TIERS, tiersInfo } from 'lib/organization-constants'

import { Typography } from 'components/typography'
import { formatStripePrice, formatInvocations } from 'lib/billing-helpers'
import { calculateInvocationSum } from 'lib/usage-helpers'
import { useOrgIngestionQuery, useGetPricesQuery } from 'hooks/api'

import styles from './styles.module.less'

export const marks = (prices) => {
  const invocationPrices = prices?.filter(item => item.metadata.type === 'invocations')
  const invocationsOptions = invocationPrices?.filter(item => item.metadata.tier !== TIERS.free)

  const max = Math.max(...invocationsOptions?.map(item => parseInt(item.metadata.invocations)))
  const min = Math.min(...invocationsOptions?.map(item => parseInt(item.metadata.invocations)))

  return invocationsOptions?.reduce((acc, item) => {
    const invoc = formatInvocations(item.metadata.invocations)
    const percentage = min === parseInt(item.metadata.invocations) ? 0 : Math.round(item.metadata.invocations / max * 100)
    return { [percentage]: invoc, ...acc }
  }, {})
}

const ConfigurePlan = ({ selectedId, invocationsCount, priceItem, handleSelectPrice }) => {
  const selectedTier = tiersInfo.find(item => item.id === selectedId)
  const { data: prices } = useGetPricesQuery()
  const { data: ingestedData } = useOrgIngestionQuery()
  const invocationsSum = calculateInvocationSum(ingestedData, 'invocations')

  const invocationPrices = prices?.filter(item => item.metadata.type === 'invocations')
  const invocations = priceItem?.metadata.invocations

  const priceItems = invocationPrices?.filter(item => item.metadata.tier !== TIERS.free && item.metadata.invocations === invocations)
  const differenceYearly = priceItems?.find(item => item.interval === 'month')?.amount * 12 - priceItems?.find(item => item.interval === 'year')?.amount

  return (
    <section className={styles.step_container}>
      <div className={styles.row}>
        <Typography.Title level={4} className={styles.label}>Selected tier: </Typography.Title>
        <p className={styles.tier}>{selectedTier?.title}</p>
      </div>
      <div className={styles.row}>
        <Typography.Title level={4} className={styles.label}>Usage (last 30days): </Typography.Title>
        <p className={styles.text}>{numeral(invocationsSum).format(invocationsSum > 999999 ? '0.0a' : '0 a')} invocations</p>
      </div>

      <Typography.Title level={4} className={styles.configure_title}>Configure plan</Typography.Title>

      <div className={styles.slider_container}>
        <div className={styles.slider}>
          <Typography.Title level={4} className={styles.text}>Monthly invocations (in millions)</Typography.Title>
          <Slider marks={marks(prices)} defaultValue={parseInt(invocationsCount)} onChange={(value) => handleSelectPrice(value, priceItem?.interval)} tipFormatter={null} step={null} />
        </div>
        <Radio.Group defaultValue={priceItem?.interval} value={priceItem?.interval} size={'small'} onChange={(e) => handleSelectPrice(invocationsCount, e.target.value)}>
          <Radio.Button value='year'>Yearly (Save 20%)</Radio.Button>
          <Radio.Button value='month'>Monthly</Radio.Button>
        </Radio.Group>
      </div>

      <div className={styles.total_container}>
        <div className={styles.price_info}>
          <Typography.Title level={4} className={styles.total_title}>Total</Typography.Title>
          <p className={styles.price}>{formatStripePrice(priceItem?.amount, priceItem?.currency)}/{priceItem?.interval === 'month' ? 'mo' : 'yr'}</p>
        </div>
        <div className={styles.price_info}>
          <p className={styles.save_tag}>{priceItem?.interval === 'month' ? 'SAVE' : "You're saving"} {formatStripePrice(differenceYearly, priceItem?.currency)}/yr</p>
          {priceItem?.interval === 'month' && <Button type={'link'} className={styles.switch_btn} onClick={() => handleSelectPrice(invocationsCount, 'year')}>Switch to yearly</Button>}
        </div>
      </div>
    </section>
  )
}

export default ConfigurePlan
