import { useQuery } from 'react-query'

import { apiFetch } from 'lib/action-helpers'
import { billingPaths as paths, SUBSCRIPTION } from '../index'
import { useOrganization } from 'hooks/context/organization-context'

const getSubscription = async (organization, identity) => {
  const data = await apiFetch(paths.subscription(organization, identity))
  return data
}

export type SubscriptionItem = {
  id: string
  price?: {
    id: string
    currency: string
    nickname?: string
    metadata?: {
      tier?: string
      type?: string
      invocations: string
    }
    interval: string
    amount: number
  }
  quantity: number
}

export type Subscription = {
  id: string
  provider: string
  status: string
  items: SubscriptionItem[]
  schedule?: {
    status: string
    id: string
  }
}

export const useGetSubscriptionQuery = ({ subscriptionId }) => {
  const { organization } = useOrganization()

  return useQuery<Subscription>(
    [...SUBSCRIPTION, subscriptionId],
    () => getSubscription(organization?.id, subscriptionId),
    {
      staleTime: Infinity,
      enabled: !!organization?.id && !!subscriptionId,
      retry: false
    }
  )
}
