import React from 'react'
import { tail, compact } from 'lodash'
import { Skeleton } from 'antd'

import Items from 'containers/inventory/shared/items'
import { objectify } from 'lib/utils'
import styles from './styles.module.less'

const ECSTasks = ({ tasks = [], loading }) => {
  const formatted = compact(tasks?.map(task => {
    if (!task) return undefined
    const attributes = objectify(task.attributes)
    const healthy = attributes.lastStatus === attributes.desiredStatus
    const timestamp = attributes.lastStatus === 'RUNNING' ? attributes.startedAt : attributes.stoppedAt

    return {
      id: task.arn,
      title: task.name,
      description: tail(attributes?.taskDefinitionArn?.split('/')),
      time: timestamp,
      background: healthy ? 'white' : 'pink'
    }
  }))

  return (
    <>
      <Items items={formatted} />
      <div className={styles.load_container}>
        {loading && <Skeleton active title={false} paragraph={{ rows: 2 }} className={styles.skeleton} />}
      </div>
    </>
  )
}

export default ECSTasks
