import React, { useMemo } from 'react'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'

import Section from 'components/layout/content/section'
import InventoryTags from 'containers/inventory/shared/tags'
import Events from 'containers/inventory/shared/events'
import InventoryResourceContainer from '../../../container'
import TargetGroupMetadata from './metadata'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import ELBLoadBalancers from './load-balancers'
import { HEALTHY } from '../../../metrics-tabs/services/elb-target-group'
import Empty from 'components/layout/content/empty'
import { useAllResourcesQuery } from 'hooks/api'

import Tabs from 'components/antd/tabs'

const Config = ({ item }) => {
  return (<>
    {item?.tags?.length && <InventoryTags tags={item.tags} inverse />}
    <TargetGroupMetadata attributes={item.attributes} inverse />
  </>)
}

const ELBTargetGroup = ({ item }) => {
  const { data: resources } = useAllResourcesQuery()

  const resourcesKeys = useMemo(() => Object.keys(resources || {}), [resources])
  const attributes = objectify(item.attributes)
  const parsedLoadBalancers = JSON.parse(attributes.loadBalancers)

  const loadBalancers = useMemo(() => parsedLoadBalancers?.map(loadBalancer => {
    return {
      ...loadBalancer,
      id: Object.values(resources || {}).find(resource => resource.externalId === loadBalancer.loadBalancerArn)?.id
    }
  }), [item, resourcesKeys])

  // Find a "parent" from target group's load balancers
  const itemWithParent = useMemo(() => ({
    ...item,
    parent: Object.values(resources || {}).find(resource => resource.externalId === parsedLoadBalancers[0].loadBalancerArn)
  }), [item, resourcesKeys])

  // no parent = no metrics
  if (!itemWithParent?.parent) return <Empty title='Metrics not found' />

  return (
    <>
      <InventoryResourceContainer item={itemWithParent} metadata={{ loadBalancers }} service={INVENTORY_SERVICES.ELBTargetGroup.id} initialChart={HEALTHY}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey="loadBalancers" items={[
                { key: 'loadBalancers', label: 'Load Balancers', children: <ELBLoadBalancers loadBalancers={loadBalancers} /> },
                { key: 'configuration', label: 'Configuration', children: <Config item={itemWithParent} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={itemWithParent?.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default ELBTargetGroup
