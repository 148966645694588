import React from 'react'
import { differenceInCalendarDays, format } from 'date-fns'
import { isEmpty, pickBy } from 'lodash'
import numeral from 'numeral'
import { Button, Tag, Skeleton, Progress, Tooltip } from 'antd'

import { useOrganization } from 'hooks/context/organization-context'
import { useOrganizationsQuery, useOrgIngestionQuery, useGetBillingQuery, useGetSubscriptionQuery, useGetNextInvoiceQuery } from 'hooks/api'

import { TIERS } from './manage-plan-modal/tier-config'
import { formatStripePrice, getSubscriptionItemPrice } from 'lib/billing-helpers'
import { calculateInvocationSum, calculateAvgUsageSince } from 'lib/usage-helpers'
import { findOrganizationAccountIds } from 'containers/settings/usage/content'
import { Typography } from 'components/typography'

import styles from './styles.module.less'

const tierColor = organization => {
  if (!organization?.tier) return ''
  if (organization.tier === TIERS.trial) return 'blue'
  if ([TIERS.free, TIERS.business, TIERS.enterprise].includes(organization.tier)) return 'green'
  // pro, or unknown
  return 'magenta'
}

const calculateUsagePercentage = (totalInvocations, currentInvocations) => {
  const total = totalInvocations ? parseFloat(totalInvocations) : 0
  const current = currentInvocations ? parseFloat(currentInvocations) : 0
  if (current === 0 && total === 0) return 0
  if (current >= total) return 100
  const percentage = (current * 100) / total
  return parseFloat(numeral(percentage).format('0.00'))
}

const CurrentSubscription = ({ setModalVisible }) => {
  const { organization } = useOrganization()
  const { data: billing, isLoading } = useGetBillingQuery()

  const { data: organizations } = useOrganizationsQuery()
  const { data: subscription, isLoading: subscriptionLoading } = useGetSubscriptionQuery({ subscriptionId: billing?.subscriptions?.[0]?.id })
  const { data: upcomingInvoice } = useGetNextInvoiceQuery({ subscriptionId: billing?.subscriptions?.[0]?.id })
  const { data: ingestionData } = useOrgIngestionQuery()

  const availableAccounts = findOrganizationAccountIds(organizations, organization)
  const availableIds = availableAccounts.map(item => item.id)
  const usageData = pickBy(ingestionData, (data, id) => availableIds.includes(id))

  const invocationsSum = calculateInvocationSum(usageData, 'invocations')
  const usageSinceCycleStart = calculateAvgUsageSince(usageData, 'invocations', subscription?.currentPeriod?.start * 1000)

  const subscriptionItem = subscription?.items?.find(sub => sub?.price?.metadata?.type === 'invocations')
  const planInvocations = subscriptionItem?.price?.metadata?.invocations
  const priceItemAfterTrial = upcomingInvoice?.lines?.find(line => line?.price?.metadata?.type === 'invocations')
  const tierItemAfterTrial = upcomingInvoice?.lines?.find(line => line?.price?.metadata?.type === 'tier')

  const usagePercentage = calculateUsagePercentage(planInvocations, usageSinceCycleStart)
  const usageProgressColor = usagePercentage >= 90 ? '#f91111' : usagePercentage >= 75 ? '#f58d06' : '#3633ab'
  const inTrial = organization.tier === TIERS.trial
  const hasSchedule = !!subscription?.schedule

  const formattedSubscriptionStart = subscription?.id ? format(subscription?.currentPeriod?.start * 1000, 'MMMM do yyyy') : 'Date not found'
  const formattedSubscriptionEnd = subscription?.id ? format(subscription?.currentPeriod?.end * 1000, 'MMMM do yyyy') : 'Date not found'

  return (
    <Skeleton active loading={isLoading || subscriptionLoading || (isEmpty(subscription) && billing?.subscriptions?.[0]?.id)}>
      <Typography.Title level={2}>Current subscription</Typography.Title>
      <section className={styles.current_container}>
        {billing?.customer?.provider === 'stripe'
          ? <div className={styles.columns}>
            <div className={styles.width}>
              {isEmpty(subscription) && <p className={styles.alert}>You have no active subscription. To continue using Dashbird please unsuspend your account.</p>}
              {inTrial && !hasSchedule && !isEmpty(subscription) && <p className={styles.alert}>Your trial ends in {differenceInCalendarDays(subscription?.currentPeriod?.end * 1000, new Date())} days. To continue using Dashbird please upgrade your account</p>}
              {inTrial && hasSchedule &&
                <p className={styles.info}>
                  After trial ends your account is upgraded to {tierItemAfterTrial?.price?.metadata.tier} tier with {numeral(priceItemAfterTrial?.price?.metadata?.invocations).format('0a').toUpperCase()} invocations per month.
                </p>}
              <div className={styles.content}>
                <div>
                  <p className={styles.label}>Tier</p>
                  <p className={styles.text}><Tag color={tierColor(organization)}>{organization.tier.toUpperCase()}</Tag></p>
                  <p className={styles.label}>Current plan</p>
                  <p className={styles.text}>{numeral(planInvocations).format(planInvocations > 999999 ? '0.0a' : '0 a')}</p>
                  <p className={styles.label}>Usage in last billing cycle</p>
                  <p className={styles.text}>{numeral(invocationsSum).format(invocationsSum > 999999 ? '0.0a' : '0 a')} invocations </p>
                  <p className={styles.label}>Usage in current billing cycle</p>
                  <div className={styles.progress_bar}>
                    <Tooltip title={`${numeral(usageSinceCycleStart).format('0a')} invocations since ${formattedSubscriptionStart}`}>
                      <Progress
                        percent={usagePercentage}
                        strokeColor={usageProgressColor}
                        strokeWidth={15}
                        strokeLinecap='square'
                        status='normal'
                      />
                    </Tooltip>
                  </div>
                </div>
                <div>
                  <p className={styles.label}>Billing interval</p>
                  <p className={styles.text}>{subscription?.id && subscriptionItem?.price ? `${subscriptionItem?.price?.interval}ly` : 'None'}</p>
                  <p className={styles.label}>Price</p>
                  <p className={styles.text}>{getSubscriptionItemPrice(subscriptionItem)} per {subscriptionItem?.price?.interval} {upcomingInvoice?.discount && `(${upcomingInvoice.discount?.coupon?.name})`}</p>
                  <p className={styles.label}>{inTrial ? 'Trial ends' : 'Next invoice'}</p>
                  <p className={styles.text}>{!inTrial ? `${formatStripePrice(upcomingInvoice?.total, upcomingInvoice?.currency)} on ${formattedSubscriptionEnd}` : formattedSubscriptionEnd}</p>
                  {inTrial && hasSchedule && (
                    <>
                      <p className={styles.label}>{'Next invoice'}</p>
                      <p className={styles.text}>{`${formatStripePrice(upcomingInvoice?.total, upcomingInvoice?.currency)} on`} {formattedSubscriptionEnd}</p>
                    </>
                  )}
                </div>
              </div>
            </div>

            <Button type='primary' onClick={() => setModalVisible(true)}>Change plan</Button>
          </div>
          : <div className={styles.aws_content}>
            <Button type='primary' onClick={() => window.open('https://console.aws.amazon.com/marketplace/home#/subscriptions', '_blank')}>Go to AWS to view your Subscription details</Button>
          </div>}
      </section>
    </Skeleton>
  )
}

export default CurrentSubscription
