import { useCallback } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { ERROR_ACTIVE } from './index'
import { eventPaths as paths } from '../index'
import { useResourceQuery } from '../inventory-v2'

const getError = async (account, error) => {
  const data = await apiFetch(paths.event(account, error))
  return data
}

export const useErrorQuery = (errorId) => {
  const queryClient = useQueryClient()
  const { account } = useOrganization()

  const activeError = queryClient.getQueryData([...ERROR_ACTIVE, errorId])
  const { data: resource } = useResourceQuery(activeError?.resource?.id || activeError?.resource)

  const transformError = useCallback(data => {
    return { ...data, resource: resource || data.resource }
  }, [resource])

  return useQuery(
    [...ERROR_ACTIVE, errorId],
    () => getError(account.id, errorId),
    {
      select: transformError,
      enabled: !!account?.id && !!errorId
    }
  )
}
