export const apiVersion = '/v1'

export const paths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/${apiVersion}/accounts/${identity}`,
  organization: (identity) => `/v1/organizations/${identity}`
}

export const pathsV2 = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v2/accounts/${identity}`,
  organization: (identity) => `/v2/organizations/${identity}`
}

export const eventPaths = {
  filter: (path, query) => `${path}?${query}`,
  account: (identity) => `/v1/accounts/${identity}`,
  events: (account) => `${paths.account(account)}/events`,
  event: (account, identity) => `${eventPaths.events(account)}/${identity}`
}

export * from './metrics'
export * from './errors'
export * from './delegations'
export * from './resource-groups'
export * from './violations'
export * from './insights'
export * from './alarms'
export * from './dashboards'
export * from './events'
export * from './events-storage'
export * from './usage'
export * from './organizations'
export * from './onboarding'
export * from './search'
export * from './invitations'
export * from './queries'
export * from './notifications/channels'
export * from './notifications/policies'
export * from './integrations'
export * from './log-sources'
export * from './billing'
export * from './inventory-v2'
