import React from 'react'
import classnames from 'classnames'
import { Skeleton } from 'antd'
import { ResolvedSolidIcon } from 'components/icons/font-awesome'
import { tiersInfo } from 'lib/organization-constants'

import styles from './styles.module.less'

const TierOptions = ({ selectedTier, setSelectedTier, loading }) => {
  if (loading) return <Skeleton />

  return (
    <section className={styles.tiers}>
      {tiersInfo.map(tier =>
        <button key={tier.id} className={classnames(styles.tier_container, selectedTier === tier.id && styles.selected)} onClick={() => setSelectedTier(0, 'month', tier.id)}>
          <div className={styles.tier_header}>
            <h5 className={styles.tier_title}>{tier.title}</h5>
            <div className={styles.price_container}>
              <p className={styles.price_number}>{tier.price}</p>
              <p className={styles.price_text}>{tier.priceTitle}</p>
            </div>
          </div>
          <h6 className={styles.subtitle}>{tier.subTitle}</h6>
          <div className={styles.text_container}>
            <p className={styles.text_small}>{tier.text}</p>
          </div>
          <p className={styles.invocations}>{tier.invocations}</p>
          <h6 className={styles.list_title}>{tier.list.title}</h6>
          <ul className={styles.list}>
            {tier.list.items.map((item, index) => (
              <li className={styles.list_item} key={index}><ResolvedSolidIcon className={styles.check} /> {item}</li>
            ))}
          </ul>
        </button>
      )}
    </section>
  )
}

export default TierOptions
