import React from 'react'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'

import Section from 'components/layout/content/section'
import InventoryResourceContainer from '../../container'
import InventoryTags from 'containers/inventory/shared/tags'
import LambdaInvocations from './invocations'
import Events from 'containers/inventory/shared/events'

import LambdaConfig from './config'
import LogGroupStatus from './loggroup-status'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { LAMBDA_INVOCATIONS } from '../../metrics-tabs/services/lambda'
import Tabs from 'components/antd/tabs'
import SearchContextWrapper from 'hooks/context/search-context'

const Config = ({ item }) => {
  const attributes = objectify(item.attributes)

  return (<>
    <LogGroupStatus item={item} inverse />
    {item?.tags?.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <LambdaConfig attributes={attributes} inverse />
  </>)
}

const Lambda = ({ item }) => {
  return (
    <>
      <InventoryResourceContainer item={item}
        service={INVENTORY_SERVICES.Lambda.id} initialChart={LAMBDA_INVOCATIONS}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey="invocations" items={[
                {
                  key: 'invocations',
                  label: 'Invocations',
                  children: (
                    <SearchContextWrapper>
                      <LambdaInvocations resource={item} />
                    </SearchContextWrapper>
                  )
                },
                { key: 'configuration', label: 'configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={item.id} errors alarms />
          </Col>
        </Row>
      </InventoryResourceContainer >
    </>
  )
}

export default Lambda
