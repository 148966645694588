import { useQueries } from 'react-query'
import memoizeOne from 'memoize-one'
import { isEqual } from 'lodash'
import { apiFetch } from 'lib/action-helpers'
import { useOrganization } from 'hooks/context/organization-context'

import {
  SEARCH,
  searchPaths as paths,
  serializeLogsPayload as serializePayload
} from './index'
import { useAllResourcesQuery } from '../inventory-v2'

const mapResourcesToLogs = (data, resources) => {
  if (!data) return undefined
  if (!resources) return data

  const mappedLogs = data?.data
    ?.map(logData => {
      if (!logData?.resource) return logData
      return {
        ...logData,
        resource: resources[logData?.resource]
      }
    })

  return { ...data, data: mappedLogs }
}

const memoizedResponse = memoizeOne(mapResourcesToLogs, isEqual)

const searchLogs = async (account, payload, queryId) => {
  const data = await apiFetch(paths.logs(account), { method: 'POST', body: JSON.stringify({ ...payload }) })

  return { ...data, id: queryId }
}

export const useSearchLogsBatchQuery = (payloads = [], refreshInterval) => {
  const { account } = useOrganization()
  const { data: resources } = useAllResourcesQuery()

  const queries = useQueries(
    payloads.map(({ id: queryId, payload: data }) => {
      const payload = serializePayload(data)

      return {
        queryKey: [SEARCH, payload, payload?.filters, account?.id, queryId],
        queryFn: () => searchLogs(account.id, payload, queryId),
        staleTime: refreshInterval ? 5 * 60 * 1000 : Infinity,
        enabled: !!account?.id && !!payload,
        refetchInterval: refreshInterval,
        refetchOnWindowFocus: false
      }
    })
  )

  return queries.map(query => {
    const data = memoizedResponse(query?.data, resources)
    return { ...query, data }
  })
}
