import React from 'react'
import { TIERS } from 'lib/organization-constants'
import { formatInvocations } from 'lib/billing-helpers'
import { Typography } from 'components/typography'
import styles from '../styles.module.less'

const CheckoutSummary = ({ title, selectedId, invocations, interval }) => {
  return (
    <div className={styles.checkout_info}>
      <div>
        <Typography.Title level={4} className={styles.label}>Selected tier: </Typography.Title>
        <p className={styles.tier}>{title} {selectedId === TIERS.free && ' - free'}</p>
      </div>
      <div >
        <Typography.Title level={4} className={styles.label}>Montly usage: </Typography.Title>
        <p className={styles.text}>{formatInvocations(invocations)} invocations</p>
      </div>
      <div>
        <Typography.Title level={4} className={styles.label}>Billing cycle: </Typography.Title>
        {selectedId === TIERS.free
          ? <p className={styles.text}>No billing</p>
          : <p className={styles.text}>{interval}ly</p>}
      </div>
    </div>
  )
}

export default CheckoutSummary
