import React, { useEffect, useState } from 'react'
import { isEmpty } from 'lodash'
import { Modal, Steps, Button, Tooltip, message } from 'antd'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { useGetPaymentMethods, useGetBillingQuery, useGetNextInvoiceQuery, useGetSubscriptionQuery, useUpdateSubscriptionQuery, useGetPricesQuery, useGetPromotionQuery, useCreatePaymentMethodQuery } from 'hooks/api'
import { useOrganization } from 'hooks/context/organization-context'
import { formatInvocations, formatStripePrice } from 'lib/billing-helpers'
import { TIERS } from 'lib/organization-constants'

import TierOptions from './tier-options'
import ConfigurePlan, { marks } from './configure-plan'
import Checkout from './checkout'
import CheckoutEnterprise from './checkout/checkout-enterprise'
import PaymentMethodModal from '../../payment/add-method-modal'

import styles from './styles.module.less'

const { Step } = Steps

const ChargeNotice = ({ total = 0, currency }) => {
  if (total === 0) return ''
  return (
    <p className={styles.margin_top}>
      You will be {total >= 0 ? 'charged' : 'credited'} {formatStripePrice(total, currency)} immediately
    </p>
  )
}

const ManageSubscriptionModal = ({ visible, setVisible }) => {
  const { organization } = useOrganization()

  const { data: billing } = useGetBillingQuery()
  const { data: prices, isLoading: pricesLoading } = useGetPricesQuery()
  const { data: subscription } = useGetSubscriptionQuery({ subscriptionId: billing?.subscriptions?.[0]?.id })
  const { data: invoiceUpcoming } = useGetNextInvoiceQuery({ subscriptionId: billing?.subscriptions?.[0]?.id })
  const { data: paymentMethods } = useGetPaymentMethods()
  const { data: promotion } = useGetPromotionQuery({ payload: 'code' })

  const { mutateAsync: updateSubscription, isLoading: subscriptionUpdating } = useUpdateSubscriptionQuery()
  const { mutateAsync: createMethod } = useCreatePaymentMethodQuery()

  const [invoicePreview, setInvoicePreview] = useState({})
  const [selectedTier, setSelectedTier] = useState('')
  const [priceItem, setPriceItem] = useState({})
  const [tierItem, setTierItem] = useState({})
  const [invocationsCount, setInvocationsCount] = useState(null)
  const [currentStep, setCurrentStep] = useState(0)
  const [showPaymentModal, setShowPaymentModal] = useState(false)
  const [stripePromise] = useState(() => loadStripe(CONFIG.STRIPE_API_KEY))

  const IN_TRIAL = subscription?.status === 'trialing'

  const findTierItem = (items) => items?.find(item => item?.price?.metadata?.type === 'tier')
  const findInvocationsItem = (items) => items?.find(item => item?.price?.metadata?.type === 'invocations')

  const hasScheduledSubscription = () => {
    const tierItem = findTierItem(invoiceUpcoming?.lines)
    const notTrialItem = tierItem?.price?.metadata?.tier !== 'trial'

    return IN_TRIAL && notTrialItem
  }

  const getCurrentInvocationsItem = (subscriptionItems) => findInvocationsItem(subscriptionItems)

  const currentInvocationsItem = getCurrentInvocationsItem(hasScheduledSubscription() ? invoiceUpcoming?.lines : subscription?.items)
  const currentPriceItem = currentInvocationsItem?.price

  const hasPaymentMethod = paymentMethods?.length > 0
  const invocationOptions = !isEmpty(prices) && marks(prices)
  const currentTier = subscription?.items?.find(item => item.price?.metadata?.type === 'tier')?.price?.metadata?.tier

  useEffect(() => {
    if (isEmpty(prices) || isEmpty(subscription) || subscriptionUpdating) return
    const formattedInvocations = formatInvocations(currentPriceItem?.metadata?.invocations)
    const invocations = Object.keys(invocationOptions).find(key => invocationOptions[key] === formattedInvocations)
    const tier = hasScheduledSubscription() ? currentPriceItem?.metadata?.tier : IN_TRIAL ? 'pro' : organization?.tier
    handleSelectPrice(invocations, currentPriceItem.interval, tier)
  }, [prices, subscription])

  const handleSelectPrice = (value = 0, interval = 'month', givenTier = selectedTier) => {
    const price = givenTier === TIERS.free
      ? prices?.find(item => item.metadata.type === 'invocations' && item.metadata.tier === 'free')
      : prices?.find(item =>
        item.nickname.startsWith(invocationOptions[value]) && item.interval === interval)

    const tier = prices?.find(item =>
      item.metadata.type === 'tier' &&
      item.metadata.tier === givenTier &&
      item.interval === interval)

    setTierItem(tier)
    setPriceItem(price)
    setInvocationsCount(value)
    setSelectedTier(givenTier)
  }

  const handleSubscriptionChange = async () => {
    const scheduled = IN_TRIAL ? { scheduled: true } : {}
    const discount = isEmpty(promotion) ? {} : { coupon: promotion?.[0]?.coupon?.id }

    const payload = {
      items: [
        { id: findInvocationsItem(subscription?.items)?.id, priceId: priceItem?.id },
        { id: findTierItem(subscription?.items)?.id, priceId: tierItem?.id }
      ],
      ...scheduled,
      ...discount
    }

    try {
      await updateSubscription({ identity: billing?.subscriptions?.[0]?.id, payload })

      setVisible(false)
      setCurrentStep(0)
      message.success('Subscription plan updated successfully')
    } catch (error) {
      message.error('Something went wrong.')
      console.log(error)
    }
  }

  const handleContactSales = () => {
    const email = 'sales@dashbird.io'
    const subject = 'Dashbird Enterprise Subscription'
    const body = `My organization ${organization?.name} is interested in the Enterprise plan`
    const newWindow = window.open(`mailto:${email}?subject=${subject}&body=${body}`, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null

    setVisible(false)
    setCurrentStep(0)
  }

  const steps = [{
    title: 'choose tier',
    next: {
      title: 'Next',
      action: (selectedTier) => setCurrentStep(selectedTier === 'pro' ? 1 : 2),
      disabled: { condition: pricesLoading }
    }
  }, {
    title: 'configure plan',
    next: {
      title: 'Continue to checkout',
      action: () => setCurrentStep(2),
      disabled: { condition: currentPriceItem?.id === priceItem?.id, title: 'Already subscribed to this item' }
    },
    back: { title: 'Back', action: () => setCurrentStep(0) }
  }, {
    title: 'finish',
    next: {
      title: selectedTier === TIERS.free ? 'Switch to free' : selectedTier === TIERS.pro ? 'Confirm' : 'Contact sales',
      action: selectedTier === TIERS.enterprise ? () => handleContactSales() : () => handleSubscriptionChange(),
      disabled: {
        condition: (selectedTier === TIERS.pro && !hasPaymentMethod) || (currentTier === TIERS.free && selectedTier === TIERS.free),
        title: currentTier === TIERS.free && selectedTier === TIERS.free ? 'You are already in the free tier' : 'Add a payment method before subscribing'
      }
    },
    back: { title: 'Back', action: (selectedTier) => setCurrentStep(selectedTier === 'pro' ? 1 : 0) }
  }]

  return (
    <Modal
      open={visible}
      onCancel={() => setVisible(false)}
      width={850}
      footer={null}
    >
      <Steps className={styles.steps_container} labelPlacement={'vertical'} current={currentStep}>
        {steps.map((step, index) => <Step key={index} title={step.title} />)}
      </Steps>
      {currentStep === 0 && <TierOptions selectedTier={selectedTier} setSelectedTier={handleSelectPrice} loading={pricesLoading} />}
      {currentStep === 1 && selectedTier === TIERS.pro && <ConfigurePlan selectedId={selectedTier} invocationsCount={invocationsCount} priceItem={priceItem} handleSelectPrice={handleSelectPrice} />}
      {currentStep === 2 && selectedTier !== TIERS.enterprise && <Checkout selectedId={selectedTier} priceItem={priceItem} tierItem={tierItem} subscription={subscription} preview={invoicePreview} setPreview={setInvoicePreview} />}
      {currentStep === 2 && selectedTier === TIERS.enterprise && <CheckoutEnterprise />}
      <div className={styles.modal_buttons}>
        <div>{steps[currentStep].back && <Button type='primary' onClick={() => steps[currentStep].back.action(selectedTier)}>{steps[currentStep].back.title}</Button>}</div>
        {currentStep === 2 && selectedTier === TIERS.pro && !hasPaymentMethod && (
          <Button
            onClick={() => setShowPaymentModal(true)}
            className={styles.add_payment_btn}
            type='secondary'
          >Add a payment Method</Button>
        )}
        {steps[currentStep].next && (
          <div className={styles.confirm_btns}>
            <Tooltip title={steps[currentStep].next.disabled?.condition ? steps[currentStep].next.disabled?.title : ''}>
              <Button
                type='primary'
                onClick={() => steps[currentStep].next.action(selectedTier)}
                loading={subscriptionUpdating || pricesLoading}
                disabled={steps[currentStep].next?.disabled?.condition}>
                {steps[currentStep].next.title}
              </Button>
            </Tooltip>
            {currentStep === 2 && !isEmpty(invoicePreview) && IN_TRIAL &&
              <ChargeNotice total={IN_TRIAL && selectedTier === TIERS.free ? 0 : invoicePreview?.total} currency={invoicePreview?.currency} />}
          </div>
        )}
      </div>
      {!hasPaymentMethod && (
        <Elements stripe={stripePromise}>
          <PaymentMethodModal visible={showPaymentModal} setVisible={setShowPaymentModal} createMethod={createMethod} />
        </Elements>
      )}
    </Modal>
  )
}

export default ManageSubscriptionModal
