import React from 'react'
import { Row, Col } from 'antd'
import { objectify } from 'lib/utils'

import InventoryTags from 'containers/inventory/shared/tags'
import InventoryResourceContainer from '../../container'
import SNSMetadata from './metadata'
import Events from 'containers/inventory/shared/events'

import Subscriptions from './subscriptions'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { SNS_MESSAGES } from '../../metrics-tabs/services/sns'
import Tabs from 'components/antd/tabs'
import Section from 'components/layout/content/section'
import { useResourcesQuery } from 'hooks/api'

const Config = ({ item }) => {
  const attributes = objectify(item.attributes)

  return (<>
    {item?.tags?.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <SNSMetadata attributes={attributes} inverse />
  </>)
}

const SNS = ({ item }) => {
  const { data: subscriptions, isLoading: loadingSubscriptions } = useResourcesQuery('sns-subscriptions', { type: 'AWS::SNS::Subscription', parent: item.id })

  return (
    <>
      <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.SNS.id} initialChart={SNS_MESSAGES}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey="subscriptions" items={[
                { key: 'subscriptions', label: 'Subscriptions', children: <Subscriptions items={Object.values(subscriptions || {})} loading={loadingSubscriptions} /> },
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default SNS
