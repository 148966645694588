import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { resourceGroupsPaths as paths, RESOURCE_GROUP_PREVIEW } from './index'

const getResourceGroupPreview = async (account, payload) => {
  const { data } = await apiFetch(paths.preview(account), { method: 'POST', body: JSON.stringify(payload) })

  return data
}

export const useResourceGroupPreviewQuery = (payload) => {
  const { account } = useOrganization()

  return useQuery(
    [...RESOURCE_GROUP_PREVIEW, payload],
    () => getResourceGroupPreview(account.id, payload),
    {
      staleTime: Infinity,
      enabled: !!account?.id && !!payload,
      refetchOnWindowFocus: false
    }
  )
}
