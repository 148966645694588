import React from 'react'
import { isEmpty } from 'lodash'

import Section from 'components/layout/content/section'
import InventoryTags from 'containers/inventory/shared/tags'
import Endpoints from './endpoints'
import InventoryResourceContainer from '../../container'
import { Col, Row } from 'antd'
import Events from 'containers/inventory/shared/events'

import { findInventoryByType, INVENTORY_SERVICES } from 'lib/resources/constants'
import { APIGW_REQUESTS } from '../../metrics-tabs/services/apigateway'
import Tabs from 'components/antd/tabs'
import { useResourcesQuery } from 'hooks/api'

const Config = ({ item }) => {
  return (<>
    {item?.tags?.length !== 0 ? <InventoryTags tags={item.tags} inverse /> : 'No configuration setup'}
  </>)
}

const ApiGateway = ({ item }) => {
  const endpointType = item?.type === INVENTORY_SERVICES.APIGatewayRest.type ? 'AWS::ApiGateway::Resource' : 'AWS::ApiGatewayV2::Endpoint'
  const { data: endpoints, isLoading: loadingEndpoints } = useResourcesQuery('apigw-endpoints', { type: endpointType, parent: item.id })

  if (isEmpty(item)) return null

  return (
    <>
      <InventoryResourceContainer item={item} service={findInventoryByType(item.type).id} initialChart={APIGW_REQUESTS}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey="requests" items={[
                { key: 'endpoints', label: 'Endpoints', children: <Endpoints loading={loadingEndpoints} endpoints={endpoints && Object.values(endpoints)} /> },
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Section>
              <Events resourceId={item.id} />
            </Section>
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default ApiGateway
