import React from 'react'
import { useLocation } from 'react-router-dom'
import Items from 'containers/inventory/shared/items'
import { AwsServiceIcon } from 'components/icons'
import { useAccountRoutes } from 'containers/routes'

const EventBridgeRuleTargets = ({ targets }) => {
  const { search } = useLocation()
  const routes = useAccountRoutes()

  const formatted = targets?.map(target => {
    return {
      id: target.id,
      url: { pathname: routes.inventory.resource.url({ resourceId: target.id }), search },
      title: target.name,
      type: target.type,
      icon: <AwsServiceIcon service={target.service} />
    }
  })

  return (
    <Items items={formatted} />
  )
}

export default EventBridgeRuleTargets
