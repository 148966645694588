export const INVENTORY = 'inventory'
export const RESOURCE_ACTIVE = [INVENTORY, 'resource']
export const RESOURCES_EXTRA = [INVENTORY, 'resources']
export const RELATIONSHIPS = [INVENTORY, 'relationships']

export { useGetResourcesV2Query as useAllResourcesQuery } from './get-inventory-resources'
export { useResourceQuery } from './get-resource'
export { useGetResourcesQuery as useResourcesQuery } from './get-resources'

export const inventoryPaths = {
  account: (identity: string) => `/v2/accounts/${identity}`,
  inventory: (identity: string) => `/${inventoryPaths.account(identity)}/inventory`,
  resources: (identity: string) => `/${inventoryPaths.inventory(identity)}/resources/search`,
  relationships: (identity: string) => `/${inventoryPaths.inventory(identity)}/relationships/search`,
}
