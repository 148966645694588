import React from 'react'
import { Slider, Radio, Button } from 'antd'
import numeral from 'numeral'
import classnames from 'classnames'

import { TIER_CONFIG } from './tier-config'
import { Typography } from 'components/typography'
import { formatStripePrice } from 'lib/billing-helpers'
import { calculateInvocationSum } from 'lib/usage-helpers'
import { useOrgIngestionQuery } from 'hooks/api'
import { BillingInterval, usePrices } from './usePrices'

import styles from './styles.module.less'

// converts shown labels to extra amount
// if the plan includes 3 accounts then label "3" actually means 0 value
const marks = (included: number, arr: number[]) => {
  return arr.reduce((acc, value) => {
    acc[value - included] = value
    return acc
  }, {})
}

const makeConfig = (included: number, markValues: number[]) => {
  return {
    included,
    marks: marks(included, markValues)
  }
}

const options = {
  lambdaInvocations: makeConfig(1, [1, 3, 7, 10, 15, 20, 30, 40, 50, 65, 80, 95]),
  awsAccounts: makeConfig(3, [3, 4, 5, 7, 10])
}
const intervalAbbr = {
  month: 'mo',
  year: 'yr',
}

const ConfigurePlan = ({ tier, billingInterval, setBillingInterval, extras, setExtras, summary }) => {
  const { calculate } = usePrices()
  const { data: ingestedData = {} } = useOrgIngestionQuery()
  const invocationsSum = calculateInvocationSum(ingestedData, 'invocations')

  // since both totals are calculated, this actually does not depend on billingInterval
  const total = React.useMemo(() => {
    const otherBillingInterval = billingInterval === BillingInterval.month ? BillingInterval.year : BillingInterval.month
    return {
      [billingInterval]: summary?.total ?? 0,
      [otherBillingInterval]: calculate(tier, otherBillingInterval, extras)?.total ?? 0,
    }
  }, [tier, extras])

  const differenceYearly = 12 * (total.month) - (total.year)

  const setExtra = React.useCallback((property, value: number) => {
    return setExtras((config: typeof extras) => ({ ...config, [property]: value }))
  }, [setExtras])

  return (
    <section className={styles.step_container}>
      {/*
      <div className={styles.row}>
        <Typography.Title level={4} className={styles.label}>Selected tier: </Typography.Title>
        <p className={styles.tier}>{TIER_CONFIG[tier]?.title}</p>
      </div>
      */}
      <div className={styles.row}>
        <Typography.Title level={4} className={styles.label}>Usage (last 30days): </Typography.Title>
        <p className={classnames(styles.text, { [styles.exceeded]: invocationsSum > summary.limits.lambdaInvocations })}>
          {numeral(invocationsSum).format(invocationsSum >= 1_000_000 ? '0.0a' : '0 a')} invocations
        </p>
      </div>

      <Typography.Title level={4} className={styles.configure_title}>Configure plan</Typography.Title>

      <div className={styles.slider_container}>
        <div className={styles.slider}>
          <Typography.Title level={4} className={styles.config_prop_title}>Monthly lambda invocations (in millions)</Typography.Title>
          <Slider
            step={1}
            marks={options.lambdaInvocations.marks}
            min={0}
            max={94}
            defaultValue={extras.lambdaInvocations / 1_000_000}
            onChange={(value) => setExtra('lambdaInvocations', 1_000_000 * value)}
            tooltip={{
              formatter: (value) => `${value}M extra invocations`
            }}
          />
          <Typography.Title level={4} className={styles.config_prop_title}>AWS accounts</Typography.Title>
          <Slider
            step={1}
            marks={options.awsAccounts.marks}
            min={0}
            max={7}
            defaultValue={extras.awsAccounts}
            onChange={(value) => setExtra('awsAccounts', value)}
            tooltip={{
              formatter: (value) => `${value} extra accounts`
            }}
          />
        </div>
        <Radio.Group defaultValue={billingInterval} value={billingInterval} size={'small'} onChange={(e) => setBillingInterval(e.target.value)}>
          <Radio.Button value='year'>Yearly (Save 20%)</Radio.Button>
          <Radio.Button value='month'>Monthly</Radio.Button>
        </Radio.Group>
      </div>

      <div className={styles.total_container}>
        <div className={styles.price_info}>
          <Typography.Title level={4} className={styles.total_title}>Total</Typography.Title>
          <p className={styles.price}>{formatStripePrice(total[billingInterval], 'usd')}/{intervalAbbr[billingInterval]}</p>
        </div>
        <div className={styles.price_info}>
          <p className={styles.save_tag}>{billingInterval === 'month' ? 'SAVE' : "You're saving"} {formatStripePrice(differenceYearly, 'usd')}/yr</p>
          {billingInterval === 'month' && <Button type={'link'} className={styles.switch_btn} onClick={() => setBillingInterval('year')}>Switch to yearly</Button>}
        </div>
      </div>
    </section>
  )
}

export default ConfigurePlan
