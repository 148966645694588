import React from 'react'
import Items from 'containers/inventory/shared/items'
import { useLocation } from 'react-router-dom'
import { useAccountRoutes } from 'containers/routes'

const ECSServices = ({ services }) => {
  const { search } = useLocation()
  const routes = useAccountRoutes()

  const formatted = services?.map(service => {
    return {
      id: service.id,
      url: { pathname: routes.inventory.resource.url({ resourceId: service.id }), search },
      title: service.name
    }
  })

  return (
    <Items items={formatted} />
  )
}

export default ECSServices
