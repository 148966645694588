import React, { useState } from 'react'
import { Button, Radio, Form, Checkbox } from 'antd'
import { sortBy, isEmpty } from 'lodash'
import classnames from 'classnames'

import { ChevronDownIcon } from 'components/icons/font-awesome'
import Section from 'components/layout/content/section'
import ListItem from 'features/side-list/item'
import { AwsServiceIcon } from 'components/icons'
import { INVENTORY_SERVICE_GROUPS } from 'lib/resources/constants'

import styles from './styles.module.less'

export const ALL_SERVICES = 'all'
const SERVICE_LIMIT = 3
const serviceList = [
  INVENTORY_SERVICE_GROUPS.lambda,
  INVENTORY_SERVICE_GROUPS['apigateway.http'],
  INVENTORY_SERVICE_GROUPS['apigateway.rest'],
  INVENTORY_SERVICE_GROUPS.sqs,
  INVENTORY_SERVICE_GROUPS.sns,
  INVENTORY_SERVICE_GROUPS.dynamodb,
  INVENTORY_SERVICE_GROUPS.ecs,
  INVENTORY_SERVICE_GROUPS.elb,
  INVENTORY_SERVICE_GROUPS['kinesis.data-streams'],
  INVENTORY_SERVICE_GROUPS['kinesis.firehose'],
  INVENTORY_SERVICE_GROUPS.stepfunctions,
  INVENTORY_SERVICE_GROUPS.rds,
  INVENTORY_SERVICE_GROUPS.opensearch
]

const Operator = ({ onChange, disabled }) => {
  return (
    <Form.Item dependencies={['selectedResources']} name='serviceOperator' defaultValue={ALL_SERVICES} className={styles.operator}>
      <Radio.Group size='small' onChange={onChange} disabled={disabled}>
        <Radio.Button value={ALL_SERVICES}>ANY</Radio.Button>
        <Radio.Button value='or'>ONLY</Radio.Button>
      </Radio.Group>
    </Form.Item>
  )
}

const sortServicesBySelected = (selected = []) => sortBy(serviceList, ({ service }) => selected.includes(service) ? 0 : 1)
const getLimitedServices = (services, selected = []) => {
  const cutoffIdx = selected.length < SERVICE_LIMIT ? SERVICE_LIMIT : selected.length
  return services.slice(0, cutoffIdx)
}

const ServiceFilters = ({ filters = {}, handlePreview, form }) => {
  const sorted = sortServicesBySelected(filters.values)

  const [services, setServices] = useState(getLimitedServices(sorted, filters.values))
  const [showList, setShowList] = useState(isEmpty(filters) ? false : filters.operator !== ALL_SERVICES)

  const fieldsDisabled = !isEmpty(form.getFieldValue('selectedResources'))

  const onOperatorChange = (e) => {
    if (e.target.value === ALL_SERVICES) {
      form.setFieldsValue({ services: [] })
      handlePreview()
    }
    setShowList(e.target.value !== ALL_SERVICES)
  }

  const showMoreServices = () => {
    if (services.length === sorted.length) return setServices(getLimitedServices(sorted, filters.values))
    const toAdd = sorted.slice(services.length)
    setServices([...services, ...toAdd])
  }

  const LoadMore = () => {
    const text = services.length === sorted.length ? 'Show less' : 'Show all'
    return (
      <Button type='link' block onClick={showMoreServices} className={styles.show_more_btn}>
        <span>
          {text}
          <ChevronDownIcon className={styles.icon_down} {...services.length === sorted.length ? { rotation: 180 } : {}} />
        </span>
      </Button>
    )
  }

  return (
    <Section title='Services' titleUpperCase>
      <div className={styles.content}>
        <span>Resources from <Operator onChange={onOperatorChange} disabled={fieldsDisabled} /> services</span>
        {showList && (
          <>
            <Form.Item
              name='services'
              className={styles.services}
              onChange={() => handlePreview()}
            >
              <Checkbox.Group>
                {services.map(service => {
                  return (
                    <Checkbox key={service.id} value={service.service} disabled={fieldsDisabled}>
                      <ListItem
                        icon={<AwsServiceIcon service={service.service} />}
                        title={service.title}
                        className={classnames(styles.service, { [styles.disabled]: !!fieldsDisabled })}
                        titleClassName={styles.service_title}
                      />
                    </Checkbox>
                  )
                })}
              </Checkbox.Group>
            </Form.Item>
            <LoadMore />
          </>
        )}
      </div>
    </Section>
  )
}

export default ServiceFilters
