import React, { createContext } from 'react'
import { Row, Col } from 'antd'

import Section from 'components/layout/content/section'
import { objectify } from 'lib/utils'
import InventoryResourceContainer from '../../../container'
import InventoryTags from 'containers/inventory/shared/tags'
import ECSServiceConfig from './config'
import ECSTasks from './tasks'
import Events from 'containers/inventory/shared/events'
import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { ECS_CPU } from '../../../metrics-tabs/services/ecs-cluster'

import Tabs from 'components/antd/tabs'
import { useResourcesQuery } from 'hooks/api'
import SearchContextWrapper from 'hooks/context/search-context'
import LogsSearch from 'features/on-demand-search'

export const ECSContext = createContext({ cpuLimit: false, memoryLimit: false })

const Drawer = ({ item, attributes }) => {
  return (<>
    {item?.tags?.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <ECSServiceConfig attributes={attributes} />
  </>)
}

const EcsService = ({ item, actions, start, end, range, configOpen, closeConfig, loading }) => {
  const {
    data: tasks,
    isLoading: loadingTasks
  } = useResourcesQuery('ecs-tasks',
    item && { type: 'AWS::ECS::TaskDefinition', parent: item.id }, 50)

  const attributes = objectify(item.attributes)

  const { cpu: cpuHardLimit, memory: memoryHardLimit } = tasks ? objectify(Object.values(tasks || [])?.[0]?.attributes || {}) : {}

  return (
    <ECSContext.Provider value={{ cpuLimit: cpuHardLimit, memoryLimit: memoryHardLimit }}>
      <InventoryResourceContainer item={item} metadata={{ attributes }} start={start} end={end} range={range} datepicker={actions}
        configOpen={configOpen} closeConfig={closeConfig} loading={loading} service={INVENTORY_SERVICES.ECSService.id} initialChart={ECS_CPU}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey="tasks" items={[
                { label: 'Tasks', key: 'tasks', children: attributes.desiredCount && <ECSTasks taskCount={attributes.desiredCount} tasks={tasks && Object.values(tasks)} loading={loadingTasks} /> },
                {
                  label: 'Logs',
                  key: 'logs',
                  children: (
                    <SearchContextWrapper>
                      <LogsSearch resource={item} />
                    </SearchContextWrapper>
                  )
                },
                { label: 'Configuration', key: 'configuration', children: <Drawer item={item} attributes={attributes} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </ECSContext.Provider>
  )
}

export default EcsService
