import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'

import Content from 'components/layout/content'
import CurrentSubscription from './current'
import ManageSubscriptionModal from './manage-subscription-modal'
import ManagePlanModal from './manage-plan-modal'
import UsageReport from './usage'
import { useOrganization } from 'hooks/context/organization-context'

import styles from './styles.module.less'

const ManageSubscriptions = () => {
  const { organization } = useOrganization()

  const [modalVisible, setModalVisible] = useState(false)
  const [planModalVisible, setPlanModalVisible] = useState(false)

  const { hash } = useLocation()

  React.useEffect(() => {
    if (hash === '#changePlan') {
      setPlanModalVisible(true)
    }
  }, [hash])

  const Title = () => <h1>Subscription for <span className={styles.org_name}>{organization.name}</span> organization</h1>

  return (
    <Content item={{}} title={<Title />} breadcrumbs={['settings', 'manage subscription']}>
      <CurrentSubscription setModalVisible={setModalVisible} setPlanModalVisible={setPlanModalVisible} />
      {modalVisible && <ManageSubscriptionModal visible={modalVisible} setVisible={setModalVisible} />}
      {!modalVisible && planModalVisible && <ManagePlanModal visible={planModalVisible} setVisible={setPlanModalVisible} />}
      <UsageReport />
    </Content>
  )
}

export default ManageSubscriptions
