export default [
  {
    id: 'price_1Nx5k0EyzI6TAN7RdfqnUgX0',
    currency: 'usd',
    nickname: 'AWS accounts / Year',
    interval: 'year',
    amount: 100000,
    metadata: { awsAccounts: '1', type: 'awsAccounts' }
  },
  {
    id: 'price_1Nx5jzEyzI6TAN7RM90YAeTH',
    currency: 'usd',
    nickname: 'AWS accounts / Month',
    interval: 'month',
    amount: 10000,
    metadata: { awsAccounts: '1', type: 'awsAccounts' }
  },
  {
    id: 'price_1NuZpdEyzI6TAN7RtaQ3Z4o5',
    currency: 'usd',
    nickname: '1M Externally Paid',
    interval: 'month',
    amount: 0,
    metadata: {
      invocations: '1000000',
      tier: 'enterprise',
      type: 'lambdaInvocations'
    }
  },
  {
    id: 'price_1NsiDAEyzI6TAN7RcCGBsJVk',
    currency: 'usd',
    nickname: 'Lambda Invocations / Year',
    interval: 'year',
    amount: 25000,
    metadata: { lambdaInvocations: '1000000', type: 'lambdaInvocations' }
  },
  {
    id: 'price_1NsNhsEyzI6TAN7RyXkRKYpR',
    currency: 'usd',
    nickname: 'Business Tier / Year',
    interval: 'year',
    amount: 99000,
    metadata: { tier: 'business', type: 'tier' }
  },
  {
    id: 'price_1Ns9SiEyzI6TAN7RM5Lx6MxJ',
    currency: 'usd',
    nickname: 'Lambda Invocations / Month',
    interval: 'month',
    amount: 2500,
    metadata: { lambdaInvocations: '1000000', type: 'lambdaInvocations' }
  },
  {
    id: 'price_1Ns518EyzI6TAN7R81qZvf4e',
    currency: 'usd',
    nickname: 'Business Tier / Month',
    interval: 'month',
    amount: 9900,
    metadata: { tier: 'business', type: 'tier', lambdaInvocations: '1000000', awsAccounts: '3' }
  },
  {
    id: 'price_1JdAFHEyzI6TAN7RqoE3lden',
    currency: 'usd',
    nickname: 'Free / Month',
    interval: 'month',
    amount: 0,
    metadata: { tier: 'free', type: 'tier', lambdaInvocations: '100000', awsAccounts: '1' }
  },
  {
    id: 'price_1Jd9zeEyzI6TAN7R812wzW2A',
    currency: 'usd',
    nickname: 'Enterprise Tier / Month',
    interval: 'month',
    amount: 0,
    metadata: { tier: 'enterprise', type: 'tier' }
  },
  {
    id: 'price_1Jd9zeEyzI6TAN7RW3Vo9YC6',
    currency: 'usd',
    nickname: 'Enterprise Tier / Year',
    interval: 'year',
    amount: 0,
    metadata: { tier: 'enterprise', type: 'tier' }
  }
]
