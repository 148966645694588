import React, { useMemo } from 'react'
import { Row, Col } from 'antd'

import { objectify } from 'lib/utils'
import InventoryResourceContainer from '../../container'
import InventoryTags from 'containers/inventory/shared/tags'
import StepFunctionsConfig from './config'
import Events from 'containers/inventory/shared/events'

import { INVENTORY_SERVICES } from 'lib/resources/constants'
import { STEPFUNCTIONS_EXECUTION } from '../../metrics-tabs/services/stepfunctions'
import Section from 'components/layout/content/section'
import Tabs from 'components/antd/tabs'
import FlowChart, { graphFromStepFunctionDefinition } from 'features/flow-chart'

const Config = ({ item }) => {
  const attributes = objectify(item.attributes)

  return (<>
    {item?.tags?.length !== 0 && <InventoryTags tags={item.tags} inverse />}
    <StepFunctionsConfig attributes={attributes} inverse />
  </>)
}

const StepFunctions = ({ item }) => {
  const definition = item.attributes.find(({ name }) => name === 'definition')
  const graph = useMemo(() => graphFromStepFunctionDefinition(JSON.parse(definition.value)), [item.id])

  return (
    <>
      <InventoryResourceContainer item={item} service={INVENTORY_SERVICES.StepFunctions.id} initialChart={STEPFUNCTIONS_EXECUTION}>
        <Row gutter={12}>
          <Col xs={24} md={16}>
            <Section>
              <Tabs defaultActiveKey="graph" items={[
                { key: 'configuration', label: 'Configuration', children: <Config item={item} /> },
                { key: 'graph', label: 'Graph', children: <FlowChart graph={graph} /> }
              ]} />
            </Section>
          </Col>
          <Col xs={24} md={8}>
            <Events resourceId={item.id} />
          </Col>
        </Row>
      </InventoryResourceContainer>
    </>
  )
}

export default StepFunctions
