import { useQuery } from 'react-query'
import { useOrganization } from 'hooks/context/organization-context'

import { apiFetch } from 'lib/action-helpers'
import { resourceGroupsPaths as paths, RESOURCE_GROUPS_LIST } from './index'

const getResourceGroups = async (account) => {
  if (!account) return

  const data = await apiFetch(paths.groups(account))
  return data
}

export const useResourceGroupsQuery = () => {
  const { account } = useOrganization()

  return useQuery(
    [...RESOURCE_GROUPS_LIST, account?.id],
    () => getResourceGroups(account?.id),
    {
      staleTime: 30 * 60 * 1000,
      enabled: !!account?.id,
      cacheTime: 30 * 60 * 1000,
      refetchOnWindowFocus: false
    }
  )
}
